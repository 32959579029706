import {Box, Button, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import React, {FC} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {AddPhotoModal} from 'components/shared';
import {useModal} from 'hooks';
import {CreatePlusIcon} from 'assets/icons';

export const AddPhotoButton: FC = () => {
    const {setValue} = useFormContext();
    const {open: openPhotoModal, handleToggle: handleTogglePhotoModal} = useModal();
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const {
        formState: {errors}
    } = useFormContext();
    const photo = useWatch({name: 'photo'});

    const setPhoto = (photo: {photo: string | File}) => {
        setValue('photo', photo);
    };

    return (
        <>
            <Button
                variant='contained'
                onClick={handleTogglePhotoModal}
                color='neutral'
                sx={(theme) => ({
                    border: errors.photo && !photo ? (theme) => '1px solid' + theme.palette.error.main : '',
                    width: isSm ? '340px' : '275px',
                    height: isSm ? '340px' : '275px',
                    mx: 'auto',
                    maxWidth: '100%',
                    p: 0,
                    color: theme.palette.text.primaryConst,
                    background: theme.palette.background.secondaryConst,
                    boxShadow: 'none !important',
                    mb: 2
                })}>
                {photo ? (
                    <Box
                        width='100%'
                        height='100%'
                        src={photo.file?.url || photo.preview}
                        component='img'
                        sx={{
                            objectFit: 'cover'
                        }}
                    />
                ) : (
                    <Stack sx={{alignItems: 'center'}} spacing={3}>
                        <CreatePlusIcon />
                        <Typography>Добавить фото</Typography>
                    </Stack>
                )}
            </Button>
            <AddPhotoModal open={openPhotoModal} handleToggle={() => handleTogglePhotoModal()} setPhoto={setPhoto} />
        </>
    );
};
