/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {UserRequest, CreateUserRequestInput, PaginatedUserRequest, UserRequestControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const userRequestControllerNewUserRequest = (createUserRequestInput: CreateUserRequestInput) => {
    const formData = new FormData();
    formData.append('title', createUserRequestInput.title);
    formData.append('content', createUserRequestInput.content);
    if (createUserRequestInput.uploadedFile !== undefined) {
        formData.append('uploadedFile', createUserRequestInput.uploadedFile);
    }

    return axiosInstance<UserRequest>({
        url: `/public/user-request/new`,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export type UserRequestControllerNewUserRequestMutationResult = NonNullable<Awaited<ReturnType<typeof userRequestControllerNewUserRequest>>>;
export type UserRequestControllerNewUserRequestMutationBody = CreateUserRequestInput;
export type UserRequestControllerNewUserRequestMutationError = unknown;

export const useUserRequestControllerNewUserRequest = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof userRequestControllerNewUserRequest>>, TError, {data: CreateUserRequestInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof userRequestControllerNewUserRequest>>, {data: CreateUserRequestInput}> = (props) => {
        const {data} = props ?? {};

        return userRequestControllerNewUserRequest(data);
    };

    return useMutation<Awaited<ReturnType<typeof userRequestControllerNewUserRequest>>, TError, {data: CreateUserRequestInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const userRequestControllerFindAll = (params?: UserRequestControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedUserRequest>({url: `/public/user-request`, method: 'get', params, signal});
};

export const getUserRequestControllerFindAllQueryKey = (params?: UserRequestControllerFindAllParams) => [
    `/public/user-request`,
    ...(params ? [params] : [])
];

export type UserRequestControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof userRequestControllerFindAll>>>;
export type UserRequestControllerFindAllQueryError = unknown;

export const useUserRequestControllerFindAll = <TData = Awaited<ReturnType<typeof userRequestControllerFindAll>>, TError = unknown>(
    params?: UserRequestControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof userRequestControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserRequestControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userRequestControllerFindAll>>> = ({signal}) =>
        userRequestControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof userRequestControllerFindAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const userRequestControllerFindOne = (id: string, signal?: AbortSignal) => {
    return axiosInstance<UserRequest>({url: `/public/user-request/${id}`, method: 'get', signal});
};

export const getUserRequestControllerFindOneQueryKey = (id: string) => [`/public/user-request/${id}`];

export type UserRequestControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof userRequestControllerFindOne>>>;
export type UserRequestControllerFindOneQueryError = unknown;

export const useUserRequestControllerFindOne = <TData = Awaited<ReturnType<typeof userRequestControllerFindOne>>, TError = unknown>(
    id: string,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof userRequestControllerFindOne>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserRequestControllerFindOneQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userRequestControllerFindOne>>> = ({signal}) => userRequestControllerFindOne(id, signal);

    const query = useQuery<Awaited<ReturnType<typeof userRequestControllerFindOne>>, TError, TData>({
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
