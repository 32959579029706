import React from 'react';
import {CustomModal} from 'components';
import {ModalProps, useModal} from 'hooks';
import {Button, Stack, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form, FormTextField} from 'components/form';
import {useAuthContext} from 'context';
import {FileAttachButton} from 'components/form/FileAttachButton';
import {LoadingButton} from '@mui/lab';
import {getUserRequestControllerFindAllQueryKey, useUserRequestControllerNewUserRequest} from 'api/user-request';
import {useQueryClient} from '@tanstack/react-query';

const FeedbackModal = ({open, handleToggle}: ModalProps) => {
    const form = useForm();
    const {watch, setValue, reset} = form;
    const postFeedback = useUserRequestControllerNewUserRequest();
    const queryClient = useQueryClient()
    const {open: openSuccessModal, handleToggle: handleToggleSuccessModal} = useModal();
    const {me} = useAuthContext();

    const onSubmit = (data: any) => {
        postFeedback
            .mutateAsync({
                data: {
                    title: data?.title,
                    content: data?.content,
                    uploadedFile: data?.file
                }
            })
            .then(() => {
                reset({title: '', content: '', file: undefined});
                queryClient.refetchQueries(getUserRequestControllerFindAllQueryKey())
                handleToggle();
                handleToggleSuccessModal();
                setTimeout(() => {
                    handleToggleSuccessModal();
                }, 2000);
            });
    };

    return (
        <>
            <CustomModal
                open={open}
                onClose={() => {
                    handleToggle();
                }}
                sx={{p: 1}}
                maxWidth='sm'>
                <Form form={form} onSubmit={onSubmit}>
                    <Stack gap={3} p={4}>
                        <Typography variant='h2' color='text.primaryConst'>Обратная связь</Typography>
                        <Stack direction='row' flexWrap='wrap' gap={5}>
                            <Typography color='text.primaryConst'>{me?.lastName + ' ' + me?.firstName}</Typography>
                            <Typography color='text.primaryConst'>{me?.email}</Typography>
                        </Stack>
                        <FormTextField name='title' rules={{required: true}} placeholder='Тема обращения' />
                        <FormTextField name='content' rules={{required: true}} multiline rows={9} placeholder='Текст обращения' />
                        <Stack alignItems='flex-end'>
                            <FileAttachButton file={watch('file')} setValue={setValue} />
                        </Stack>
                        <Stack direction='row' gap={3} justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                color='neutral'
                                onClick={() => {
                                    reset();
                                    handleToggle();
                                }}
                                sx={{
                                    height: 'auto',
                                    p: '14px 80px'
                                }}>
                                Отменить
                            </Button>
                            <LoadingButton loading={postFeedback.isLoading} type='submit' variant='contained' sx={{p: '14px 80px'}}>
                                Отправить
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Form>
            </CustomModal>
            <CustomModal
                open={openSuccessModal}
                onClose={() => handleToggleSuccessModal()}
                hiddenCloseIcon
                sx={{'.MuiDialogContent-root': {p: 2}, '*': {color: '#2c2a29'}}}
                fullWidth={false}>
                Ваше обращение принято и будет рассмотрено в ближайшее время.
                <br /> Ответ будет направлен на рабочую почту.
            </CustomModal>
        </>
    );
};

export default FeedbackModal;
