import {useUserRequestControllerFindOne} from 'api/user-request';
import React, {FC} from 'react';
import {CustomModal} from 'components';
import {ModalProps} from 'hooks';
import {Form} from 'components/form';
import {Button, Divider, Stack, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useAuthContext} from 'context';
import {Download} from '@mui/icons-material';
import saveAs from 'file-saver';

export const RequestModal: FC<{id: string} & ModalProps> = (props) => {
    const {id, open, handleToggle} = props;
    const {data} = useUserRequestControllerFindOne(id);
    const form = useForm();
    const {me} = useAuthContext();
    const userMessage = data?.messages?.[0];
    const adminMessage = data?.messages?.[1];

    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleToggle();
            }}
            sx={{p: 1}}
            maxWidth='sm'>
            <Form form={form}>
                <Stack gap={3} p={4}>
                    <Typography variant='h2' color='text.primaryConst'>Обратная связь</Typography>
                    <Stack direction='row' flexWrap='wrap' gap={5}>
                        <Typography color='text.primaryConst'>{me?.lastName + ' ' + me?.firstName}</Typography>
                        <Typography color='text.primaryConst'>{me?.email}</Typography>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' variant='h3' textTransform='none' color='text.primaryConst'>
                            Тема обращения
                        </Typography>
                        <Typography color='text.primaryConst'>{data?.title}</Typography>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' variant='h3' textTransform='none' color='text.primaryConst'>
                            Текст обращения
                        </Typography>
                        <Typography color='text.primaryConst'>{userMessage?.content}</Typography>
                    </Stack>
                    {userMessage?.file && (
                        <Stack alignItems='start'>
                            <Button
                                color='neutral'
                                variant='contained'
                                startIcon={<Download />}
                                onClick={() => {
                                    if (userMessage?.file.url) {
                                        saveAs(userMessage?.file.url, userMessage?.file.name || 'download');
                                    }
                                }}>
                                {userMessage?.file.name}
                            </Button>
                        </Stack>
                    )}
                    {adminMessage?.content && (
                        <>
                            <Divider />
                            <Stack>
                                <Typography fontWeight='bold' variant='h3' textTransform='none' color='text.primaryConst'>
                                    Текст ответа
                                </Typography>
                                <Typography color='text.primaryConst'>{adminMessage?.content}</Typography>
                            </Stack>
                            {adminMessage?.file && (
                                <Stack alignItems='start'>
                                    <Button
                                        color='neutral'
                                        variant='contained'
                                        startIcon={<Download />}
                                        onClick={() => {
                                            if (adminMessage?.file.url) {
                                                saveAs(adminMessage?.file.url, adminMessage?.file.name || 'download');
                                            }
                                        }}>
                                        {adminMessage?.file.name}
                                    </Button>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </Form>
        </CustomModal>
    );
};
