import React, {useMemo, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {TableScroll} from 'components';
import {BookIcon, CalendarColorIcon, StatusColorIcon} from 'assets/icons';
import {PublicStoryDto} from 'api/mDDPublic.schemas';
import {useInfiniteQuery} from '@tanstack/react-query';
import {flatInfinityQuery} from 'utils';
import moment from 'moment';
import {HeaderTitle} from '../components/HeaderTitle';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserRequestControllerFindAllQueryKey, userRequestControllerFindAll} from 'api/user-request';
import {useModal} from 'hooks';
import {RequestModal} from './RequestModal';

export const getStatus = (data: PublicStoryDto) => {
    const STATUS_TYPE = {
        PROCESSED: {
            title: 'Обработано',
            color: 'success.main'
        },
        UNDER_CONSIDERATION: {
            title: 'На рассмотрении',
            color: 'warning.main'
        }
    };
    return STATUS_TYPE[data?.status as keyof typeof STATUS_TYPE];
};

const columns = (onClick: (row: PublicStoryDto) => void) => [
    {
        label: <HeaderTitle title='Название' icon={<BookIcon />} />,
        dataKey: 'name',
        value: (data: PublicStoryDto) => (
            <Typography sx={{cursor: 'pointer'}} onClick={() => onClick(data)}>
                {data?.title}
            </Typography>
        )
    },
    {
        label: <HeaderTitle title='Статус' icon={<StatusColorIcon />} />,
        dataKey: 'status',
        value: (data: PublicStoryDto) => <Box color={getStatus(data)?.color}>{getStatus(data)?.title}</Box>
    },
    {
        label: <HeaderTitle title='Дата создания' icon={<CalendarColorIcon />} />,
        dataKey: 'date',
        value: (data: PublicStoryDto) => moment(data?.createdAt).format('DD.MM.YYYY')
    }
];
export const Requests = () => {
    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
        getUserRequestControllerFindAllQueryKey(),
        ({pageParam = 0}) =>
            userRequestControllerFindAll({
                'pagination[skip]': pageParam * 9,
                'pagination[take]': 9
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const stories = useMemo(() => flatInfinityQuery(data), [data]);
    const loading = isLoading || hasNextPage || isFetchingNextPage;
    const {open, handleToggle} = useModal();
    const [id, setId] = useState<string | null>(null);
    const onClick = (row: PublicStoryDto) => {
        setId(row.id.toString())
        handleToggle()
    };

    return (
        <Box maxWidth='100%' overflow='auto'>
            {data && stories.length < 1 ? (
                <Typography textAlign='center'>Нет данных</Typography>
            ) : (
                <TableScroll columns={columns(onClick)} loading={loading} data={stories} load={fetchNextPage} height={312} minWidth={800} />
            )}
            {id && open && <RequestModal id={id} open={open} handleToggle={handleToggle} />}
        </Box>
    );
};
