import React, {useEffect, useMemo} from 'react';
import {Box, Grid, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {Comments} from './Comments';
import moment from 'moment';
import {useQsParams} from 'hooks';
import {InputComment} from './InputComment';
import {getStoryControllerFindAllQueryKey, useStoryControllerFindOne} from 'api/story';
import {CustomModal, Visit} from 'components';
import {useStoryViewControllerCreate} from 'api/story-view';
import {FavoriteIcon} from '../components';
import {useQueryClient} from '@tanstack/react-query';
import {STORIES_STATUS} from 'utils';
import {StoryTag} from 'api/mDDPublic.schemas';

export const StoryModal = () => {
    const queryClient = useQueryClient();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [params, setParams] = useQsParams();
    const {storyId} = params;
    const {data: story} = useStoryControllerFindOne(storyId);

    const addStoryView = useStoryViewControllerCreate();

    useEffect(() => {
        const handleClick = (event: any) => {
            if (event.target.dataset.type === 'mention') {
                searchAuthor(event.target.dataset.label);
            }
        };

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        if (storyId && story && !story?.isViewed) {
            addStoryView.mutateAsync({data: {storyId}});
        }
    }, [storyId, story]);

    const searchTag = (el: StoryTag) => {
        setParams({...params, storyId: undefined, search: el?.tag.name.toLowerCase()});
    };

    const searchAuthor = (el: string) => {
        setParams({...params, storyId: undefined, search: el});
    };
    const handleClose = () => {
        setParams({...params, storyId: undefined});
        queryClient.refetchQueries(getStoryControllerFindAllQueryKey());
    };
    const ImageCard = () => {
        return (
            <Box sx={{width: '100%', mb: 3, aspectRatio: '1/1'}}>
                <img width='100%' style={{borderRadius: '10px', height: '100%', objectFit: 'cover'}} src={story?.file?.url} alt='avatar' />
            </Box>
        );
    };
    const storyAuthors = useMemo(
        () => story?.authors?.map((author) => author.user?.lastName + ' ' + author.user?.firstName).join(', '),
        [story?.authors]
    );
    const Author = () => {
        return (
            <>
                <Box sx={{display: 'flex', justifyContent: 'space-between', fontStyle: 'italic', my: 3}}>
                    <Typography sx={{mr: 2}}>{storyAuthors}</Typography>
                    <Typography>{moment(story?.createdAt).format('DD.MM.YYYY')}</Typography>
                </Box>
                <Typography sx={{fontStyle: 'Italic', mb: 3}}>
                    {story?.tags?.map((el, index) => (
                        <Typography
                            key={index}
                            onClick={() => searchTag(el)}
                            display='inline-block'
                            sx={{'&.MuiTypography-root:hover': {fontWeight: 'bold'}, cursor: 'pointer'}}>
                            {' #' + el?.tag?.name.toLowerCase()}&nbsp;
                        </Typography>
                    ))}
                </Typography>
            </>
        );
    };
    const isPublished = story?.status === STORIES_STATUS.PUBLISHED;

    return (
        <CustomModal open={!!params.storyId} onClose={handleClose}>
            <Visit story>
                <Grid container sx={{p: 4, background: (theme) => theme.palette.background.card}} columnSpacing={2.5}>
                    <Grid item xs={12} md={4} sx={{order: isMd ? 0 : -1}}>
                        {isMd && (
                            <>
                                <ImageCard />
                                <Author />
                                {isPublished && <InputComment />}
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end', mb: 2}}>
                            <Typography variant='h2' sx={{wordBreak: 'break-word'}}>
                                {story?.title}
                            </Typography>

                            {isPublished && <FavoriteIcon id={story?.id!} isFavorite={!!story?.isFavorite} />}
                        </Box>
                        {!isMd && <ImageCard />}
                        {story?.videoFile && (
                            <Stack alignItems='center' sx={{mb: 5}}>
                                <video controls src={story?.videoFile.url} width={400} controlsList="nodownload"/>
                            </Stack>
                        )}
                        <Box
                            component='div'
                            sx={{
                                mb: 5,
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                                '& .mention': {
                                    borderRadius: '2px',
                                    backgroundColor: '#c6ddf4',
                                    cursor: 'pointer',
                                    color: '#2c2a29'
                                }
                            }}
                            dangerouslySetInnerHTML={{__html: story?.description?.replace(/<p><\/p>/g, '</br>') as string}}
                        />

                        {!isMd && <Author />}
                        {isPublished && <Comments story={story!} />}
                        {!isMd && <InputComment />}
                    </Grid>
                </Grid>
            </Visit>
        </CustomModal>
    );
};
