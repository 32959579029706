/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {
    Story,
    CreateStoryPublicDto,
    PaginatedPublicStoryDto,
    StoryControllerFindAllParams,
    UpdateDraftDto,
    Comment,
    CreateCommentInput,
    PublicOneStoryDto,
    PaginatedPublicCommentDto,
    StoryControllerGetStoryCommentsParams
} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const storyControllerCreate = (createStoryPublicDto: CreateStoryPublicDto) => {
    const formData = new FormData();
    formData.append('title', createStoryPublicDto.title);
    formData.append('description', createStoryPublicDto.description);
    if (createStoryPublicDto.fileId !== undefined) {
        formData.append('fileId', createStoryPublicDto.fileId.toString());
    }
    if (createStoryPublicDto.uploadedFile !== undefined) {
        formData.append('uploadedFile', createStoryPublicDto.uploadedFile);
    }
    if (createStoryPublicDto.videoFileId !== undefined) {
        formData.append('videoFileId', createStoryPublicDto.videoFileId.toString());
    }
    if (createStoryPublicDto.uploadedVideoFile !== undefined) {
        formData.append('uploadedVideoFile', createStoryPublicDto.uploadedVideoFile);
    }
    if (createStoryPublicDto.tagIds !== undefined) {
        createStoryPublicDto.tagIds.forEach((value) => formData.append('tagIds', value));
    }
    if (createStoryPublicDto.isDraft !== undefined) {
        formData.append('isDraft', createStoryPublicDto.isDraft);
    }

    return axiosInstance<Story>({url: `/public/story`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}, data: formData});
};

export type StoryControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof storyControllerCreate>>>;
export type StoryControllerCreateMutationBody = CreateStoryPublicDto;
export type StoryControllerCreateMutationError = unknown;

export const useStoryControllerCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyControllerCreate>>, TError, {data: CreateStoryPublicDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyControllerCreate>>, {data: CreateStoryPublicDto}> = (props) => {
        const {data} = props ?? {};

        return storyControllerCreate(data);
    };

    return useMutation<Awaited<ReturnType<typeof storyControllerCreate>>, TError, {data: CreateStoryPublicDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const storyControllerFindAll = (params?: StoryControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedPublicStoryDto>({url: `/public/story`, method: 'get', params, signal});
};

export const getStoryControllerFindAllQueryKey = (params?: StoryControllerFindAllParams) => [`/public/story`, ...(params ? [params] : [])];

export type StoryControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof storyControllerFindAll>>>;
export type StoryControllerFindAllQueryError = unknown;

export const useStoryControllerFindAll = <TData = Awaited<ReturnType<typeof storyControllerFindAll>>, TError = unknown>(
    params?: StoryControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof storyControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStoryControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof storyControllerFindAll>>> = ({signal}) => storyControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof storyControllerFindAll>>, TError, TData>({queryKey, queryFn, ...queryOptions}) as UseQueryResult<
        TData,
        TError
    > & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const storyControllerUpdateDraft = (id: string, updateDraftDto: UpdateDraftDto) => {
    const formData = new FormData();
    formData.append('title', updateDraftDto.title);
    formData.append('description', updateDraftDto.description);
    if (updateDraftDto.fileId !== undefined) {
        formData.append('fileId', updateDraftDto.fileId.toString());
    }
    if (updateDraftDto.uploadedFile !== undefined) {
        formData.append('uploadedFile', updateDraftDto.uploadedFile);
    }
    if (updateDraftDto.videoFileId !== undefined) {
        formData.append('videoFileId', updateDraftDto.videoFileId.toString());
    }
    if (updateDraftDto.uploadedVideoFile !== undefined) {
        formData.append('uploadedVideoFile', updateDraftDto.uploadedVideoFile);
    }
    if (updateDraftDto.tagIds !== undefined) {
        updateDraftDto.tagIds.forEach((value) => formData.append('tagIds', value));
    }
    if (updateDraftDto.isDraft !== undefined) {
        formData.append('isDraft', updateDraftDto.isDraft);
    }

    return axiosInstance<Story>({url: `/public/story/draft/${id}`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}, data: formData});
};

export type StoryControllerUpdateDraftMutationResult = NonNullable<Awaited<ReturnType<typeof storyControllerUpdateDraft>>>;
export type StoryControllerUpdateDraftMutationBody = UpdateDraftDto;
export type StoryControllerUpdateDraftMutationError = unknown;

export const useStoryControllerUpdateDraft = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyControllerUpdateDraft>>, TError, {id: string; data: UpdateDraftDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyControllerUpdateDraft>>, {id: string; data: UpdateDraftDto}> = (props) => {
        const {id, data} = props ?? {};

        return storyControllerUpdateDraft(id, data);
    };

    return useMutation<Awaited<ReturnType<typeof storyControllerUpdateDraft>>, TError, {id: string; data: UpdateDraftDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const storyControllerCreateComment = (id: string, createCommentInput: CreateCommentInput) => {
    return axiosInstance<Comment>({
        url: `/public/story/${id}/comment`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: createCommentInput
    });
};

export type StoryControllerCreateCommentMutationResult = NonNullable<Awaited<ReturnType<typeof storyControllerCreateComment>>>;
export type StoryControllerCreateCommentMutationBody = CreateCommentInput;
export type StoryControllerCreateCommentMutationError = unknown;

export const useStoryControllerCreateComment = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyControllerCreateComment>>, TError, {id: string; data: CreateCommentInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyControllerCreateComment>>, {id: string; data: CreateCommentInput}> = (
        props
    ) => {
        const {id, data} = props ?? {};

        return storyControllerCreateComment(id, data);
    };

    return useMutation<Awaited<ReturnType<typeof storyControllerCreateComment>>, TError, {id: string; data: CreateCommentInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const storyControllerFindOne = (id: string, signal?: AbortSignal) => {
    return axiosInstance<PublicOneStoryDto>({url: `/public/story/${id}`, method: 'get', signal});
};

export const getStoryControllerFindOneQueryKey = (id: string) => [`/public/story/${id}`];

export type StoryControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof storyControllerFindOne>>>;
export type StoryControllerFindOneQueryError = unknown;

export const useStoryControllerFindOne = <TData = Awaited<ReturnType<typeof storyControllerFindOne>>, TError = unknown>(
    id: string,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof storyControllerFindOne>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStoryControllerFindOneQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof storyControllerFindOne>>> = ({signal}) => storyControllerFindOne(id, signal);

    const query = useQuery<Awaited<ReturnType<typeof storyControllerFindOne>>, TError, TData>({
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const storyControllerGetStoryComments = (id: string, params?: StoryControllerGetStoryCommentsParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedPublicCommentDto>({url: `/public/story/${id}/comments`, method: 'get', params, signal});
};

export const getStoryControllerGetStoryCommentsQueryKey = (id: string, params?: StoryControllerGetStoryCommentsParams) => [
    `/public/story/${id}/comments`,
    ...(params ? [params] : [])
];

export type StoryControllerGetStoryCommentsQueryResult = NonNullable<Awaited<ReturnType<typeof storyControllerGetStoryComments>>>;
export type StoryControllerGetStoryCommentsQueryError = unknown;

export const useStoryControllerGetStoryComments = <TData = Awaited<ReturnType<typeof storyControllerGetStoryComments>>, TError = unknown>(
    id: string,
    params?: StoryControllerGetStoryCommentsParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof storyControllerGetStoryComments>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStoryControllerGetStoryCommentsQueryKey(id, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof storyControllerGetStoryComments>>> = ({signal}) =>
        storyControllerGetStoryComments(id, params, signal);

    const query = useQuery<Awaited<ReturnType<typeof storyControllerGetStoryComments>>, TError, TData>({
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
