/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Auth
 * The MDD NestJS app Auth API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {User, LoginUserResultDto, LoginUserDto, RefreshTokensInput, ResetPasswordInput} from './mDDAuth.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const authControllerMe = (signal?: AbortSignal) => {
    return axiosInstance<User>({url: `/auth/me`, method: 'get', signal});
};

export const getAuthControllerMeQueryKey = () => [`/auth/me`];

export type AuthControllerMeQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerMe>>>;
export type AuthControllerMeQueryError = unknown;

export const useAuthControllerMe = <TData = Awaited<ReturnType<typeof authControllerMe>>, TError = unknown>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof authControllerMe>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAuthControllerMeQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerMe>>> = ({signal}) => authControllerMe(signal);

    const query = useQuery<Awaited<ReturnType<typeof authControllerMe>>, TError, TData>({queryKey, queryFn, ...queryOptions}) as UseQueryResult<
        TData,
        TError
    > & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const authControllerLoginUser = (loginUserDto: LoginUserDto) => {
    return axiosInstance<LoginUserResultDto>({url: `/auth/login`, method: 'post', headers: {'Content-Type': 'application/json'}, data: loginUserDto});
};

export type AuthControllerLoginUserMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerLoginUser>>>;
export type AuthControllerLoginUserMutationBody = LoginUserDto;
export type AuthControllerLoginUserMutationError = unknown;

export const useAuthControllerLoginUser = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authControllerLoginUser>>, TError, {data: LoginUserDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerLoginUser>>, {data: LoginUserDto}> = (props) => {
        const {data} = props ?? {};

        return authControllerLoginUser(data);
    };

    return useMutation<Awaited<ReturnType<typeof authControllerLoginUser>>, TError, {data: LoginUserDto}, TContext>(mutationFn, mutationOptions);
};
export const authControllerRefreshTokens = (refreshTokensInput: RefreshTokensInput) => {
    return axiosInstance<LoginUserResultDto>({
        url: `/auth/refresh`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: refreshTokensInput
    });
};

export type AuthControllerRefreshTokensMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerRefreshTokens>>>;
export type AuthControllerRefreshTokensMutationBody = RefreshTokensInput;
export type AuthControllerRefreshTokensMutationError = unknown;

export const useAuthControllerRefreshTokens = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, {data: RefreshTokensInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerRefreshTokens>>, {data: RefreshTokensInput}> = (props) => {
        const {data} = props ?? {};

        return authControllerRefreshTokens(data);
    };

    return useMutation<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, {data: RefreshTokensInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const authControllerResetPassword = (resetPasswordInput: ResetPasswordInput) => {
    return axiosInstance<boolean>({
        url: `/auth/reset-password`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: resetPasswordInput
    });
};

export type AuthControllerResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerResetPassword>>>;
export type AuthControllerResetPasswordMutationBody = ResetPasswordInput;
export type AuthControllerResetPasswordMutationError = unknown;

export const useAuthControllerResetPassword = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authControllerResetPassword>>, TError, {data: ResetPasswordInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerResetPassword>>, {data: ResetPasswordInput}> = (props) => {
        const {data} = props ?? {};

        return authControllerResetPassword(data);
    };

    return useMutation<Awaited<ReturnType<typeof authControllerResetPassword>>, TError, {data: ResetPasswordInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
