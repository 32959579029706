import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {EditorContent, useEditor} from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import {mentionSuggestionOptions} from './suggestion';
import Mention from '@tiptap/extension-mention';
import {alpha, Box, InputAdornment, Tooltip} from '@mui/material';
import {Labeled} from 'components/form/Labeled';
import {CharacterCount} from '@tiptap/extension-character-count';
import {TiptapEditorProvider} from './editorContext';
import {FormatButtons} from './FormatButtons';
import {useController, UseControllerProps} from 'react-hook-form';
import useDebouncedFocus from './useDebouncedFocus';
import {ErrorOutline as ErrorOutlineIcon} from '@mui/icons-material';

export const RichTextInput = forwardRef((props: UseControllerProps, ref) => {
    const {name} = props;
    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Italic,
            Strike,
            Underline,
            Mention.configure({
                HTMLAttributes: {
                    class: 'mention'
                },
                suggestion: mentionSuggestionOptions
            }),
            CharacterCount.configure({
                limit: 2000
            })
        ],
        content: ''
    });

    const isFieldFocused = useDebouncedFocus({editor});

    useImperativeHandle(ref, () => ({
        editor: editor
    }));

    const {
        field,
        fieldState: {error}
    } = useController({name, defaultValue: ''});

    useEffect(() => {
        if (!editor) {
            return;
        }

        const handleEditorUpdate = () => {
            if (editor.isEmpty) {
                field.onChange('');
                // field.onBlur();
                return;
            }

            const html = editor.getHTML();
            field.onChange(html);
            // field.onBlur();
        };

        editor.on('update', handleEditorUpdate);
        editor.on('blur', field.onBlur);

        return () => {
            editor.off('update', handleEditorUpdate);
            editor.off('blur', field.onBlur);
        };
    }, [editor, field]);

    return (
        <TiptapEditorProvider value={editor}>
            <Labeled label='История'>
                <Box
                    sx={(theme) => ({
                        '& .tiptap': {
                            borderRadius: '5px',
                            height: 200,
                            overflowY: 'scroll',
                            outline: 'none',
                            padding: 1.75,
                            color: theme.palette.primary.dark,
                            backgroundColor: theme.palette.background.secondaryConst,
                            transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
                            ...(isFieldFocused && {
                                transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
                                backgroundColor: '#ffffff'
                            }),
                            border:
                                (error && '1px solid ' + theme.palette.error.main) || (isFieldFocused && '1px solid #86B7FE') || '1px solid #ced4da',
                            boxShadow:
                                (error && isFieldFocused && `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`) ||
                                (isFieldFocused && `${alpha('#1976d2', 0.25)} 0 0 0 0.2rem`) ||
                                'none'
                        },
                        '& .mention': {
                            borderRadius: '2px',
                            backgroundColor: '#c6ddf4'
                        },
                        '& p': {marginBottom: '0!important'}
                    })}>
                    <FormatButtons />
                    <Box sx={{position: 'relative'}}>
                        <EditorContent editor={editor} />
                        {error && (
                            <InputAdornment position='end' sx={{position: 'absolute', top: 30, right: 17}}>
                                <Tooltip title={error?.message?.trim() ? error.message : ''}>
                                    <ErrorOutlineIcon color='error' />
                                </Tooltip>
                            </InputAdornment>
                        )}
                        <Box color='neutral.main' sx={{position: 'absolute', bottom: -20, right: 0, fontSize: 12}}>
                            {editor?.storage.characterCount.characters() + ' / ' + 2000}
                        </Box>
                    </Box>
                </Box>
            </Labeled>
        </TiptapEditorProvider>
    );
});
