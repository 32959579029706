import {useController} from 'react-hook-form';
import {Button, Stack, Typography} from '@mui/material';
import {AttachIcon, CloseIcon} from 'assets/icons';

export const VideoButton = () => {
    const {
        field: {value, onChange}
    } = useController({name: 'video'});
    const fileName = value?.file ? value?.file?.name : value?.name?.split('.').slice(0, -1).join('.');
    const fileExtension = value?.file ? value?.file?.extension : value?.name?.split('.').pop();

    const deleteFile = () => {
        onChange(null);
    };

    const onFileChange = (file: any) => {
        if (file.size > 20000000) {
            alert('Размер файла не должен превышать 20 мб')
        } else {
            onChange(file)
        }
    }

    return (
        <Stack sx={{mb: 6}}>
            {value ? (
                <Button fullWidth variant='text' color='inherit' endIcon={<CloseIcon />} onClick={deleteFile}>
                    <Typography noWrap color='text.primaryConst'>
                        {fileName}
                    </Typography>
                    <Typography sx={{mr: 'auto'}} color='text.primaryConst'>{`.${fileExtension}`}</Typography>
                </Button>
            ) : (
                <label>
                    <input type='file' accept="video/mp4, video/webm" style={{display: 'none'}} onChange={(event) => onFileChange(event?.target?.files?.[0])} />
                    <Button fullWidth startIcon={<AttachIcon />} variant='contained' color='neutral' component='span'>
                        <Typography color='text.primaryConst'>Прикрепить видео</Typography>
                    </Button>
                </label>
            )}
        </Stack>
    );
};
