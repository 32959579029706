import React from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ReactComponent as Image} from './assets/img.svg';
import {Button, Card, Container, Grid, Link, Stack, Typography} from '@mui/material';
import {ERROR_MESSAGE, ROUTES} from 'utils';
import {useAuthControllerResetPassword} from 'api/authentication';
import {Form, FormTextField} from 'components/form';
import {useNavigate} from 'react-router-dom';

const schema = yup.object({
    username: yup.string().required(ERROR_MESSAGE.REQUIRED)
});

type FormData = {
    username: string;
};

export const ResetPassword = () => {
    const navigate = useNavigate();
    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            username: ''
        }
    });
    const {setError} = form;
    const resetPassword = useAuthControllerResetPassword();
    const onSubmit = (data: FormData) => {
        resetPassword
            .mutateAsync({data: {email: data.username}})
            .then(() => {
                alert('Письмо с паролем отправлено на почту');
                navigate(ROUTES.SIGN_IN);
            })
            .catch((err) => {
                if (err.error === 'TOO_MANY_PASSWORD_RESET_REQUESTS') {
                    setError('username', {
                        type: 'not_found',
                        message:
                            'Слишком много попыток, попробуйте через час'
                    });
                } else {
                    setError('username', {
                        type: 'not_found',
                        message:
                            'Указанный адрес электронной почты не найден. Если вы считаете, что это ошибка, воспользуйтесь кнопкой обратной связи или напишите обращение на mfc-support-mdd@mfc.mos.ru'
                    });
                }
            });
    };

    return (
        <Container sx={{pt: 7.5}}>
            <Grid container alignItems='flex-end' columnSpacing={14}>
                <Grid item xs={12} lg={6.5} mb={{xs: 5, lg: 0}}>
                    <Stack direction='row' spacing={1}>
                        <Typography variant='h1' sx={{color: 'secondary.dark', lineHeight: 1}}>
                            Мой
                        </Typography>
                        <Typography variant='h1' sx={{color: 'secondary.main', lineHeight: 1}}>
                            профиль
                        </Typography>
                    </Stack>
                    <Typography variant='h1' sx={{color: 'primary.main'}} mb={2}>
                        вход
                    </Typography>
                    <Form form={form} onSubmit={onSubmit}>
                        <Card
                            sx={{
                                padding: '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3
                            }}>
                            <FormTextField name='username' label='Почта' />
                            <Typography variant='body2'>На указанную почту будут отправлены данные для восстановаления доступа к профилю</Typography>

                            <Button variant='contained' type='submit' sx={{mt: 3}}>
                                Восстановить доступ
                            </Button>
                        </Card>
                    </Form>
                    <Stack sx={{mt: 2}} direction='row' justifyContent='space-between'>
                        <Link
                            href={`mailto:${process.env.REACT_APP_FEEDBACK_EMAIL}`}
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                textDecoration: 'none',
                                fontWeight: 'bold'
                            }}>
                            <Typography variant='body2'>Обратная связь</Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <Image />
                </Grid>
            </Grid>
        </Container>
    );
};
