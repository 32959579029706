import React from 'react';
import {Avatar, Button, Container, Stack, Typography} from '@mui/material';
import {ProfileTabs} from './components/ProfileTabs';
import {getUserName, getUserNameInitials, removeTokens} from 'utils';
import {useAuthContext} from 'context';
import {useQueryClient} from '@tanstack/react-query';
import {getAuthControllerMeQueryKey} from 'api/authentication';
import {useModal} from 'hooks';
import {useUserControllerUpdateAvatar} from 'api/user';
import {AddPhotoModal} from 'components/shared';

export const Profile = () => {
    const {me} = useAuthContext();
    const queryClient = useQueryClient();

    const handleExit = () => {
        removeTokens();
        queryClient.refetchQueries(getAuthControllerMeQueryKey());
    };
    const updateAvatar = useUserControllerUpdateAvatar();
    const {open: openPhotoModal, handleToggle: handleTogglePhotoModal} = useModal();
    const setAvatar = (avatar: any) => {
        updateAvatar
            .mutateAsync({
                data: {fileId: avatar?.fileId, uploadedFile: !avatar?.fileId ? avatar : undefined}
            })
            .then(() => {
                queryClient.refetchQueries(getAuthControllerMeQueryKey());
            });
    };

    return (
        <>
            <Container>
                <Stack direction='row' flexWrap='wrap' alignItems='center' justifyContent='space-between' mt={7.5}>
                    <Stack direction='row' flexWrap='wrap'>
                        <Typography variant='h1' color='secondary.dark'>
                            Мой&nbsp;
                        </Typography>
                        <Typography variant='h1' color='secondary'>
                            Профиль
                        </Typography>
                    </Stack>
                    <Button color='neutral' onClick={handleExit} variant='outlined' sx={{px: 6, mb: 1}}>
                        Выйти
                    </Button>
                </Stack>
                <Stack direction='row' spacing={3} mt={3}>
                    <Avatar sx={{width: 80, height: 80, cursor: 'pointer'}} onClick={handleTogglePhotoModal}>
                        {me?.avatar ? (
                            <img width='100%' style={{borderRadius: '10px', height: '100%', objectFit: 'cover'}} src={me?.avatar?.url} alt='avatar' />
                        ) : (
                            getUserNameInitials(getUserName(me))
                        )}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight='bold' variant='h3' textTransform='none'>
                            {getUserName(me)}
                        </Typography>
                        <Typography variant='body2' mb={0.5}>
                            {me?.mainDepartment?.abbreviation}
                        </Typography>
                        <Typography color='grey.500' variant='body2'>
                            {me?.email}
                        </Typography>
                    </Stack>
                </Stack>
                <ProfileTabs />
            </Container>
            <AddPhotoModal open={openPhotoModal} handleToggle={() => handleTogglePhotoModal()} setPhoto={setAvatar} />
        </>
    );
};
